import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import './about-us.scss'


const AboutUs = () => (
    <Layout>
        <SEO title='About Us' />
        <h2 style={{ margin: '1em 0' }}>会社概要</h2>
        <div className='line-dot'></div>
        <h3 className='aboutus-h3'>会社名</h3>
        <p className='aboutus-p' style={{ marginBottom: '8px' }}>株式会社 Beyond One</p>
        <div className='line-dot'></div>
        <h3 className='aboutus-h3'>所在地</h3>
        <p className='aboutus-p'>〒810-0001</p>
        <p className='aboutus-p' style={{ marginBottom: '8px' }}>福岡県福岡市中央区天神2-3-36 ibb fukuokaビル 5階</p>
        <div className='line-dot'></div>
        <h3 className='aboutus-h3'>設立</h3>
        <p className='aboutus-p' style={{ marginBottom: '8px' }}>2020年 4月 7日</p>
        <div className='line-dot'></div>
        <h3 className='aboutus-h3'>代表</h3>
        <p className='aboutus-p' style={{ marginBottom: '8px' }}>代表取締役　恩田昭美</p>
        <div className='line-dot'></div>
        <h3 className='aboutus-h3'>事業目的</h3>
        <div className='aboutus-grid'>
            <ul>
                <li className='aboutus-p'>経営に関するコンサルティング</li>
                <div className='line-dot'></div>
                <li className='aboutus-p'>ブランディング、マーケティングリサーチ、マーケティングコミュニケー ション、
                パブリックリレーションズ、セールスプロモーション、マーチャ ンダイジング等に関する企画、
                立案、支援及びコンサルティング</li>
                <div className='line-dot'></div>
                <li className='aboutus-p'>コーポレート・アイデンティティ(企業のカラーやシンボルなど、統一さ れたイメージを発信すること)
                に関するコンサルティング</li>
                <div className='line-dot'></div>
                <li className='aboutus-p'>営業ツールのデザイン、企画、開発、制作、販売及び運営並びにそれらに 関するコンサルティング </li>
                <div className='line-dot'></div>
                <li className='aboutus-p'>インターネットを含むコンピュータシステム及びコンピュータソフトウェ アの企画、制作、開発、販売、
                保守及び管理並びにそれらに関するコンサ ルティング</li>
                <div className='line-dot'></div>
                <li className='aboutus-p'>ウェブサイト、モバイルコンテンツ及びアプリケーションソフトウェアの 企画、設計、開発、制作、運営及び販売</li>
                <div className='line-dot'></div>
                <li className='aboutus-p'>ビジネスプロセスアウトソーシング業務の請負</li>
                <div className='line-dot'></div>
                <li className='aboutus-p'>各種商品の企画、開発、製造、管理、販売及び販売代行</li>
                <div className='line-dot'></div>
                <li className='aboutus-p'>人材育成のための講習会、研修、セミナー及び講演会の企画及び運営並び にそれらの受託</li>
                <div className='line-dot'></div>
                <li className='aboutus-p'>協会・団体ビジネスの立上げ及び運営に関する支援業務</li>
                <div className='line-dot'></div>
                <li className='aboutus-p'>オフィス、店舗等の内装及び外装の企画、設計、デザイン及び施工並びに それらに関するコンサルティング</li>
                <div className='line-dot'></div>
                <li className='aboutus-p'>前各号に附帯又は関連する一切の事業</li>
            </ul>
        </div>
        <div className='line-dot'></div>
        <h3 className='aboutus-h3'>取引銀行</h3>
        <p className='aboutus-p' style={{ marginBottom: '8px' }}>筑邦銀行　福岡営業部支店</p>
        <div className='line-dot'></div>
    </Layout>
)

export default AboutUs